import {
  Analytics,
  AuthenticatedSection,
  AuthProvider,
} from '@readcloud/common';
import { Toaster } from '@readcloud/ui';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import App from './app/app';
import { environment } from './environments/environment';
import { store } from './redux/store';
import { Suspense, useEffect } from 'react';
import { reportActions, AppDispatch } from '@readcloud/state';
import { WebAppVersion, ContentVersion } from '@readcloud/common/config.json';

ReactDOM.render(
  <Suspense
    fallback={
      <div className=".h-screen .w-full .grid .place-content-center">
        Loading...
      </div>
    }
  >
    <Provider store={store}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <AuthProvider
            client_id={environment.auth.client_id}
            authority={environment.auth.authority}
            signinCallback={() => {
              (store.dispatch as AppDispatch)(
                reportActions.asyncActions.createUserActivity({
                  type: 'Login',

                  'App Name': 'Webapp',
                  'Webapp Version': WebAppVersion,
                  'Content Version': ContentVersion,
                })
              );
            }}
          >
            <Analytics apiKey={environment.posthog_id}>
              <AuthenticatedSection>
                <App />
              </AuthenticatedSection>
              <div
                style={{ position: 'absolute' }}
                className="readcloud-ui readcloud-ui-reset"
              >
                <Toaster />
              </div>
            </Analytics>
          </AuthProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
