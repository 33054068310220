import { Institution } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './index';

export interface InstitutionAddRequest {
  name: string;
  resellerInstitutionName: string;
  resellerName: string;
}
export const addInstitution = (req: InstitutionAddRequest) =>
  getApiv14().post('/add/institution', req);

export const deleteInstitution = (name: string) =>
  getApiv14().delete('/delete/institution', { data: { name } });

export const institutionRcsResources = (apiKey: string) =>
  getApiv14().get('/institution_rcs_resources', { params: { apiKey } });

// ##############################################
// NEW ENDPOINTS
// ##############################################
export const getInstitution = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/institution/' + institutionId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const getInstitutionBranding = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/institution/' + institutionId + '/branding', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const getAllInstitutions = (config?: AxiosRequestConfig) =>
  getApiv14().get('/institution/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createInstitution = (
  institution: Institution,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/institution/', [institution], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createInstitutions = (
  institutions: Institution[],
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/institution/', institutions, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const removeInstitution = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/institution/' + institutionId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateInstitution = (
  institution: Partial<Institution>,
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/institution/', [institution], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateInstitutions = (
  institutions: Partial<Institution>[],
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/institution/', institutions, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const getInstitutionClouds = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/institution/' + institutionId + '/school_clouds/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const getInstitutionBooks = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/institution/' + institutionId + '/school_books/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateInstitutionSubjects = (
  institutionId: string,
  subjects: Partial<Institution['classNameMappings']['subjects']>,
  config?: AxiosRequestConfig
) =>
  getApiv14().put('/institution/' + institutionId + '/subjects', subjects, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateInstitutionYearLevels = (
  institutionId: string,
  yearLevels: Partial<Institution['classNameMappings']['yearLevels']>,
  config?: AxiosRequestConfig
) =>
  getApiv14().put(
    '/institution/' + institutionId + '/year_levels',
    yearLevels,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const updateInstitutionGenericRegexps = (
  institutionId: string,
  genericRegexps: Partial<Institution['classNameMappings']['genericRegexps']>,
  config?: AxiosRequestConfig
) =>
  getApiv14().put(
    '/institution/' + institutionId + '/generic_regexps',
    genericRegexps,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const updateInstitutionSpecificRegexps = (
  institutionId: string,
  specificRegexps: Partial<Institution['classNameMappings']['specificRegexps']>,
  config?: AxiosRequestConfig
) =>
  getApiv14().put(
    '/institution/' + institutionId + '/specific_regexps',
    specificRegexps,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const provisionBooks = (
  institutionId: string,
  config?: AxiosRequestConfig,
  dryRun?: boolean,
  async?: boolean,
  unprovisionLast?: boolean,
  unprovisionAll?: boolean
) =>
  getApiv14().post(
    '/provision_books/' + institutionId + (async ? '?async=' + async : ''),
    null,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
        dryRun: dryRun,
        unprovisionLast: unprovisionLast,
        unprovisionAll: unprovisionAll,
      },
    }
  );

export const purchaseBooks = (
  institutionId: string,
  config?: AxiosRequestConfig,
  dryRun?: boolean,
  async?: boolean
) =>
  getApiv14().post(
    '/purchase_books/' + institutionId + '?' + (async ? 'async=' + async : ''),
    null,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
        dryRun: dryRun,
      },
    }
  );

export const reconcileOrders = (
  institutionId: string,
  config?: AxiosRequestConfig,
  dryRun?: boolean,
  async?: boolean
) =>
  getApiv14().post(
    '/reconcile_orders/' +
      institutionId +
      '?' +
      (async ? 'async=' + async : ''),
    null,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
        dryRun: dryRun,
      },
    }
  );

export const mapClassNames = (
  institutionId: string,
  dryRun?: boolean,
  cloudId?: string,
  jobId?: string,
  async?: boolean,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(
    `/map_class_names/${institutionId}?${cloudId ? 'cloudId=' + cloudId : ''}${
      async ? (cloudId ? '&async=' + async : 'async=' + async) : ''
    }`,
    {
      dryRun: dryRun,
      jobId: jobId,
    },
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const getActiveItems = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/grouped_order_items/' + institutionId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const getTransactionInventory = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/grouped_order_items_ex/' + institutionId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const generatePublisherReport = (
  institutionId: string,
  async?: boolean,
  config?: AxiosRequestConfig
) =>
  getApiv14().get(
    `/publisher_report/${institutionId}?${async ? 'async=' + async : ''}`,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const getPublisherReports = (
  institutionId: string,
  fromDate?: string,
  toDate?: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get(
    `/get_publisher_reports/${institutionId}?${
      fromDate ? 'dateFrom=' + fromDate : ''
    }&${toDate ? 'dateTo=' + toDate : ''}`,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const headstartTransition = (
  institutionId: string,
  config?: AxiosRequestConfig,
  dryRun?: boolean,
  async?: boolean
) =>
  getApiv14().post(
    '/headstart_transition/' +
      (institutionId ? institutionId : 'all') +
      '?dryRun=' +
      dryRun +
      (async ? '&async=' + async : ''),
    {},
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const unprovisionBooks = (
  institutionId: string,
  config?: AxiosRequestConfig,
  dryRun?: boolean,
  removeAll?: boolean
) =>
  getApiv14().post(
    '/unprovision_books/' +
      (institutionId ? institutionId : 'all') +
      '?dryRun=' +
      dryRun +
      '&removeAll=' +
      !!removeAll,
    {},
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const fileUpload = (
  file: File | Blob,
  filename: string,
  config?: AxiosRequestConfig
) => {
  const form = new FormData();
  form.append('file', file, filename);

  return getApiv14().post(
    '/upload_file?fileType=SchoolData&fileSubType=StreamImport&fileName=' +
      filename,
    form,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );
};

export const removeLockedBooks = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/handle_expired_books/' + institutionId + '?async=true', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
