import { GlobalTag, Order } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const getAllOrders = (
  filterQueryString?: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/order/all' + '?' + filterQueryString, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const getOrder = (orderId: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/order/' + orderId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createOrders = (orders: Order[], config?: AxiosRequestConfig) =>
  getApiv14().post('/order', orders, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createOrder = (order: Order, config?: AxiosRequestConfig) =>
  getApiv14().post('/order', [order], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateOrders = (
  orders: Partial<Order>[],
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/order', orders, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateOrder = (
  order: Partial<Order>,
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/order', [order], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const removeOrder = (orderId: string, config?: AxiosRequestConfig) =>
  getApiv14().delete('/order/' + orderId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const searchAdvancedOrders = (
  req: {
    limit: number;
    offset: number;
    root: any;
    order?: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/order', req, config);

export const mergeOrders = (
  orderIds: string,
  effectiveFrom: string,
  deleteMerged: boolean,
  config?: AxiosRequestConfig
) =>
  ///order/merge?ids=641d284ffb5f6426a6d64beb,641d2874fb5f6426a6d64bef,641d28aefb5f6426a6d64bf5\&effectiveFrom=2022-06-01\&deleteOrders=true
  getApiv14().post(
    '/order/merge?ids=' +
      orderIds +
      '&effectiveFrom=' +
      effectiveFrom +
      '&deleteOrders=' +
      deleteMerged,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );

export const getGlobalTags = (config?: AxiosRequestConfig) =>
  getApiv14().get('/global_tag/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateGlobalTag = (
  uid: string,
  req: Partial<GlobalTag>,
  config?: AxiosRequestConfig
) => getApiv14().patch('/global_tag/' + uid, req, config);

export const createGlobalTag = (
  req: Partial<GlobalTag>,
  config?: AxiosRequestConfig
) => getApiv14().post('/global_tag', [req], config);

export const deleteGlobalTag = (uid: string, config?: AxiosRequestConfig) =>
  getApiv14().delete('/global_tag/' + uid, config);
