// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { isWincApp } from '@readcloud/common';
import { WebAppEnvironment } from './types';

export const environment: WebAppEnvironment = {
  auth: {
    client_id: isWincApp() ? 'Ni8XL5tsrUBY4' : 'RCL2021',
    redirect_uri: `${location.origin}/authentication/callback`,
    post_logout_redirect_uri: location.origin,
    response_type: 'code',
    scope: 'openid profile email offline_access',
    authority:
      import.meta.env.VITE_ACCOUNTS_AUTHORITY ||
      'https://accounts.readcloud-test.com',
    silent_redirect_uri: `${location.origin}/authentication/silent_callback`,
    automaticSilentRenew: true,
    triggerAuthFlow: true,
  },
  posthog_id: 'phc_2MutYC8qqc7ITzJEMiomP92Utpjk5g8pNCbrbeqN8Zl',
  content_url:
    import.meta.env.VITE_CONTENT_APP_HOST || 'd20i71e5k4zxir.cloudfront.net',
};
