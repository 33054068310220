import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowPointer,
  faBan,
  faBook,
  faBookmark,
  faBookOpen,
  faBookOpenReader,
  faCheck,
  faCircle,
  faCircleExclamation,
  faCirclePlay,
  faCirclePlus,
  faClose,
  faCloud,
  faComments,
  faCopy,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faFileLines,
  faFilter,
  faFont,
  faGear,
  faHighlighter,
  faImage,
  faInfo,
  faInfoCircle,
  faLink,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassArrowRight,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMessage,
  faPaintBrush,
  faPencil,
  faPhotoFilm,
  faPlayCircle,
  faPlugCirclePlus,
  faPlus,
  faPrint,
  faRefresh,
  faSearch,
  faSquare,
  faTableList,
  faTrash,
  faUser,
  faX,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import './main.scss';
import './tailwind.css';
import '@readcloud/ui/lib/style.css';

library.add(
  faCircle,
  faArrowLeft,
  faCheck,
  faPencil,
  faTrash,
  faCirclePlay,
  faPlayCircle,
  faCloud,
  faLock,
  faInfo,
  faFilter,
  faComments,
  faEye,
  faMagnifyingGlass,
  faPlus,
  faRefresh,
  faMagnifyingGlassArrowRight,
  faCirclePlus,
  faPlugCirclePlus,
  faGear,
  faTableList,
  faAngleLeft,
  faAngleRight,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faXmark,
  faBook,
  faAngleUp,
  faCircleExclamation,
  faClose,
  faEllipsisV,
  faBookmark,
  faBookOpen,
  faBookOpenReader,
  faLink,
  faMessage,
  faPrint,
  faSearch,
  faEyeSlash,
  faUser,
  faPhotoFilm,
  faImage,
  faFont,
  faFileLines,
  faArrowPointer,
  faPaintBrush,
  faSquare,
  faHighlighter,
  faX,
  faInfoCircle,
  faCopy,
  faBan
);
export * from './components';
export * from './tailwind';
