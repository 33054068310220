import { tryCatchAndNotify } from '@root/libs/common/src/lib/rum';
import { ViewState } from './types';
import { trackEvent } from '@root/libs/common/src';
import { getReaderAnalyticsContext } from '../../analytics';
const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const UpdateViewStateThunkAction = async (
  payload: Partial<ViewState>,
  { getState }
) => {
  //api call
  //return payload;
  const name = 'app_state';
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // make full payload
  const viewState = {
    ...(getState().ReaderState.readerData.viewState || {}),
    ...payload,
  };

  const dateMs = Date.now();

  fetch(absolutePath + `/api/v14/update/${name}?currentDateMs=${dateMs}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify([
      {
        state: { ...viewState },
        tags: {
          webComponent: 'Reader',
          realm: 'ViewState',
          bookId: getState().ReaderState.readerData.bookId,
        },
        date: Date.now(),
      },
    ]),
  }).catch((e) => {
    //we need a system to make this optimistic and then backtrack the state update if it fails here.
    console.log('Error: ', e);
  });

  return payload;
};
