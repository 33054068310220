import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CloudsAndContentState } from './types';

const initialState: CloudsAndContentState = {
  scrollToCloud: undefined,
  scrollCloudToPosition: 0,
  scrollContainerToPosition: 0,
};

const name = 'cloudsAndContent';

const asyncActions = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateCloudsAndContentState(
      state,
      action: PayloadAction<Partial<CloudsAndContentState>>
    ) {
      Object.keys(action.payload).forEach(
        (key) => (state[key] = action.payload[key])
      );
    },
  },
});

const { actions, reducer } = slice;

export const cloudsAndContentReducer = reducer;
export const cloudsAndContentActions = { ...actions, asyncActions };
